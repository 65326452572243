import { createRouter, createWebHistory } from "vue-router";
import Layout from '@/layout';
import communityLayout from "@/layout/community.vue";
// redirect: '/index',
const routes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    name: 'login',
    meta: { title: '登录' }
  },
  {
    path: '/',
    redirect: '/web/index'
  },
  {
    path: '/web',
    component: Layout,
    children: [
      // keepAlive: true
      {
        path: 'index',
        component: () => import('@/views/index'),
        name: 'index',
        meta: {
          title: 'AI门户网', icon: 'dashboard', affix: true,
          data: [
            { title: "AI门户  ▏ 聚合全网精品AI工具 一键直达AI应用" },
            { keywords: "AI门户，AI导航，AI工具箱，AI工具箱导航，人工智能写作工具、人工智能图像工具、人工智能视频工具、AI办公工具、AI聊天机器人、AI音频工具、AI编程工具、AI设计工具、AI工具、AI人脉，人工智能教育，AI论坛，AI门户官网" },
            { description: "AI门户为您提供一站式精品AI工具。节省您的时间，搜罗全网精品热门AI工具，一键直达应用。AI门户由深圳市人工智能产业协会监制，旨在为市场运营，技术开发，专家学者，企业家，学生等群体推荐最好用的AI工具。" }
          ]
        }
      },
      {
        path: '/web/index/',
        component: () => import('@/views/index'),
        name: 'index',
        meta: {
          title: '首页', icon: 'dashboard', affix: true, keepAlive: true,
          data: [
            { title: "AI门户  ▏ 聚合全网精品AI工具 一键直达AI应用" },
            { keywords: "AI门户，AI导航，AI工具箱，AI工具箱导航，人工智能写作工具、人工智能图像工具、人工智能视频工具、AI办公工具、AI聊天机器人、AI音频工具、AI编程工具、AI设计工具、AI工具、AI人脉，人工智能教育，AI论坛，AI门户官网" },
            { description: "AI门户为您提供一站式精品AI工具。节省您的时间，搜罗全网精品热门AI工具，一键直达应用。AI门户由深圳市人工智能产业协会监制，旨在为市场运营，技术开发，专家学者，企业家，学生等群体推荐最好用的AI工具。" }
          ]
        }
      },
      {
        path: '/news',
        component: () => import('@/views/news'),
        name: 'news',
        meta: {
          title: '资讯',
          data: [
            { title: '人工智能综合资讯：技术进展、应用、商业动态与行业论坛' },
            { keywords: "人工智能新闻, AI技术进展, AI应用领域, AI商业动态, AI学术会议, AI行业论坛" },
            { description: "人工智能新闻资讯，AI技术进展，AI应用领域，AI商业动态，AI学术会议，AI行业论坛" }
          ]
        }
      }, {
        path: '/news/detail',
        component: () => import('@/views/news/detail'),
        name: 'newsDetail',
        meta: { title: '资讯详情', }
      }, {
        path: '/application',
        component: () => import('@/views/application'),
        name: 'application',
        meta: {
          title: '应用', data: [
            { title: 'AI工具一站式导航：直达官方网站，高效筛选最佳人工智能工具' },
            { keywords: "AI工具导航, 一站式AI工具选择, AI工具官方链接, 人工智能工具推荐, 高效筛选AI工具" },
            { description: "iii.为您提供全面的AI工具应用矩阵，用户点击直达官方网站，无需再次搜索。简化筛选过程，一站式选择最适合的AI工具，节省您的宝贵时间。" }
          ]
        }
      },
      {
        path: '/appList',
        component: () => import('@/views/application/appList'),
        name: 'appList',
        meta: { title: '应用' }
      },
      {
        path: '/appListSearch',
        component: () => import('@/views/application/search'),
        name: 'applicationList',
        meta: { title: '应用搜索' }
      },
      {
        path: 'appMyAiTool',
        component: () => import('@/views/application/myAiTool'),
        name: 'appMyAiTool',
        meta: { title: '我的AI工具' }
      },
      {
        path: '/application/detail',
        component: () => import('@/views/application/detail'),
        name: 'applicationDetail',
        meta: { title: '应用详情' }
      }, {
        path: '/connections',
        component: () => import('@/views/connections'),
        name: 'connections',
        meta: {
          title: '人脉', data: [
            { title: 'AI行业人脉大全：专家、学者与产业精英一览' },
            { keywords: "AI行业人脉, 人工智能专家名片, AI学者联系方式, 技术经理电话, 商务经理联系, AI行业合作" },
            { description: "专为AI产业会员提供的人脉名片矩阵，汇集学者、专家、技术与商务经理等行业精英的联系方式，助您快速建立与维护人脉网络，拓展商业合作与学术交流。" }
          ]
        }
      }, {
        path: '/activity',
        component: () => import('@/views/activity'),
        name: 'activity',
        meta: { title: '活动' }
      }, {
        path: '/activity/detail',
        component: () => import('@/views/activity/detail'),
        name: 'activityDetail',
        meta: { title: '活动详情' }
      }, {
        path: '/train',
        component: () => import('@/views/train'),
        name: 'train',
        meta: {
          title: '教育', data: [
            { title: 'AI教育中心：职业培训、证书认证与公益教育项目' },
            { keywords: "AI职业培训, 人工智能证书认证, AI教育中心, AI专业培训, AI认证考评" },
            { description: "提供全方位的AI教育资源，包括专业职业培训、证书认证考评以及公益教育项目。资深导师亲授，为您在人工智能领域开辟学习、认证与成长的通路。" }
          ]
        }
      }, {
        path: '/train/detail',
        component: () => import('@/views/train/detail'),
        name: 'trainDetail',
        meta: { title: '教育详情' }
      }, {
        path: '/personal',
        component: () => import('@/views/personal'),
        name: 'personal',
        meta: { title: '个人中心' }
      },
      {
        path: '/personal/income',
        component: () => import('@/views/personal/income'),
        name: 'income',
        meta: { title: '我的收益' }
      },
      {
        path: '/personal/explain',
        component: () => import('@/views/personal/explain'),
        name: 'explain',
        meta: { title: '收益说明' }
      },
      {
        path: '/personal/promotionRecord',
        component: () => import('@/views/personal/promotionRecord'),
        name: 'promotionRecord',
        meta: { title: '推广列表' }
      }, {
        path: '/personal/message',
        component: () => import('@/views/personal/message'),
        name: 'message',
        meta: { title: '个人信息' }
      },
      {
        path: '/personal/poster',
        component: () => import('@/views/personal/poster'),
        name: 'poster',
        meta: { title: '海报' }
      },
      //  {
      //   path: '/personal/appMember',
      //   component: () => import('@/views/personal/appMember'),
      //   name: 'appMember',
      //   meta: { title: 'AI门户VIP' }
      // }, 
      {
        path: '/personal/appPayment',
        component: () => import('@/views/personal/appPayment'),
        name: 'appPayment',
        meta: { title: '支付订单  ' }
      }, {
        path: '/personal/firm',
        component: () => import('@/views/personal/firm'),
        name: 'firm',
        meta: { title: '企业信息' }
      }, {
        path: '/personal/feedback',
        component: () => import('@/views/personal/feedback'),
        name: 'feedback',
        meta: { title: '问题反馈' }
      },
      {
        path: '/personal/memberCenter',
        component: () => import('@/views/personal/memberCenter'),
        name: 'memberCenter',
        meta: { title: '会员中心' }
      },
      {
        path: '/personal/myPromotion',
        component: () => import('@/views/personal/myPromotion'),
        name: 'myPromotion',
        meta: { title: '我的推广' }
      },
      {
        path: '/personal/password',
        component: () => import('@/views/personal/password'),
        name: 'password',
        meta: { title: '修改密码' }
      }, {
        path: '/personal/collection',
        component: () => import('@/views/personal/collection'),
        name: 'collection',
        meta: { title: '我的收藏' }
      }, {
        path: '/personal/application',
        component: () => import('@/views/personal/application'),
        name: 'mineApplication',
        meta: { title: '我的应用' }
      }, {
        path: '/personal/application/listing',
        component: () => import('@/views/personal/listing'),
        name: 'listing',
        meta: { title: '上架应用' }
      }, {
        path: '/personal/ckeditor',
        component: () => import('@/views/personal/ckeditor'),
        name: 'ckeditor',
        meta: { title: '富文本' }
      }, {
        path: '/hotSpot',
        component: () => import('@/views/hotSpot'),
        name: 'hotSpot',
        meta: { title: 'Top 100' }
      },
      {
        path: '/inlayPage',
        component: () => import('@/views/inlayPage'),
        name: 'inlayPage',
        meta: { title: '星河社区' },
      },
      {
        path: '/agreement',
        component: () => import('@/views/agreement'),
        name: 'agreement',
        meta: { title: '用户协议' },
        beforeEnter: (to, from, next) => {
          to.meta.title = to.query.title;
          next()
        }
      }, {
        path: '/appLabels',
        component: () => import('@/views/personal/appLabels'),
        name: 'appLabels',
        meta: { title: '标签' }
      }, {
        path: '/appExtend',
        component: () => import('@/views/personal/appExtend'),
        name: 'appExtend',
        meta: { title: '资料' }
      },
      {
        path: '/download',
        component: () => import('@/views/download'),
        name: 'download',
        meta: { title: '下载' }
      },
      {
        path: '/public-topic',
        component: () => import('@/views/personal/publishTopic.vue'),
        name: 'publicTopic',
        meta: { title: '发布的主题' }
      }
    ]
  },
  {
    path: '/aiCommunity',
    redirect: '/aiCommunity/index',
  },
  {
    path: '/aiCommunity',
    component: communityLayout,
    children: [
      {
        path: '/aiCommunity/index',
        component: () => import('@/views/aiCommunity/index'),
        meta: { title: 'AI社区' }
      },
      {
        path: '/aiCommunity/community',
        component: () => import('@/views/aiCommunity/community'),
        meta: { title: '社区首页' }
      },
      {
        path: '/aiCommunity/tag-cloud',
        component: () => import('@/views/aiCommunity/TagCloud'),
        meta: { title: '标签云' }
      },
      {
        path: '/aiCommunity/ai-data',
        component: () => import('@/views/aiCommunity/AIData'),
        meta: { title: 'AI资料' }
      },
      {
        path: '/aiCommunity/role-square',
        component: () => import('@/views/aiCommunity/RoleSquare'),
        meta: { title: '角色广场' }
      },
      {
        path: '/aiCommunity/image-square',
        component: () => import('@/views/aiCommunity/ImageSquare'),
        meta: { title: '图片广场' }
      },
      {
        path: '/aiCommunity/detail/:id',
        component: () => import('@/views/aiCommunity/AIDataDetail'),
        meta: { title: '社区列表' }
      },
      {
        path: '/aiCommunity/tag-detail/:id',
        component: () => import('@/views/aiCommunity/TagDetail.vue'),
        meta: { title: '标签详情' }
      },
      {
        path: '/agent-detail',
        component: () => import('@/views/aiCommunity/AgentApp.vue'),
        meta: { title: '应用详情' }
      },
    ]
  },
  {
    path: '/commonet/detail/:id',
    component: () => import('@/views/comment/index.vue'),
    meta: { title: '社区详情' }
  },
  {
    path: '/commonet/new-commont/:topId',
    component: () => import('@/views/comment/NewComment.vue'),
    meta: { title: '发布新帖' }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

//router/index.js
const writer = (to) => {
  //首先找到head里的meta
  const deleArr = [];
  document.head.childNodes.forEach(item => {
    switch (item.tagName) {
      case "META":
        deleArr.push(item);
        break;
      case "TITLE":
        //顺便设置title
        document.title = to.meta.title || to.name;
        break;
    }
  });

  //删除原来的meta
  deleArr.forEach(item => {
    document.head.removeChild(item);
  })

  //添加想要的meta（全局）
  const metas = document.createElement("META");
  const creatArr = [
    { charset: "utf-8" },
    { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
    //视图缩放
    { name: "viewport", content: "width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no,minimal-ui" }
  ];

  //将单个路由设定的meta加到creatArr数组中
  //局部meta和全局meta重复时，局部meta替换全局meta
  const tmpArr = to.meta.data ? to.meta.data.concat() : [];

  if (tmpArr.length > 0) {
    to.meta.data.forEach((item, index) => {
      creatArr.forEach((ele, ind) => {
        if (Object.keys(item)[0] == Object.keys(ele)[0]) {
          creatArr[ind] = JSON.parse(JSON.stringify(item));
          tmpArr.splice(index, 1);
        }
      });
    });
  }

  //生成合并后的数组
  const eleArr = creatArr.concat(tmpArr);

  //将设定的值写入文档片段
  const creatFrag = document.createDocumentFragment();
  eleArr.forEach(ele => {
    creatFrag.append(metas.cloneNode());
    Object.entries(ele).forEach(item => {
      creatFrag.lastChild.setAttribute(item[0], item[1]);
    });
  });

  //将文档片段写入head
  document.head.prepend(creatFrag);
}
router.beforeEach((to, from, next) => {
  writer(to);   //调用自动添加meta

  next();
})

export default router;




