<template>
  <!-- v-if="adaptiveStore.isShowView" -->
  <div class="bottom">
    版权所有@2023 ​<a href="https://www.szaicx.com/" target="_blank"
      >深圳市人工智能产业协会</a
    >
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 技术支持：​<a
      href="https://www.szis.com.cn/"
      target="_blank"
      >深圳市网新新思软件有限公司</a
    >
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19099358号-2</a>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a
      target="_blank"
      href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a313x.manage_type_myprojects.i7.5.1bd73a81HXXwJI&recordcode=33011002011859"
      p-id="48"
      data-spm-anchor-id="a313x.manage_type_myprojects.i7.4"
      ><img
        style="width: 16px; height: 16px; vertical-align: sub"
        src="//gw.alicdn.com/tfs/TB1jwakrbH1gK0jSZFwXXc7aXXa-20-20.png"
        p-id="49"
        data-spm-anchor-id="a313x.manage_type_myprojects.i7.i1.1bd73a81HXXwJI"
    /></a>
    <a
      href="//www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010453"
      >粤公网安备44030502010453号</a
    >
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.bottom {
  // width: 100%;
  min-width: 1458px;
  background: var(--anchor-color);
  z-index: 99;
  color: var(--text-color);
  text-align: center;
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  a:hover {
    text-decoration: underline;
  }
}
</style>
