<template>
  <el-aside width="250px" class="menu-box">
    <!-- @close="handleClose" -->
    <el-menu class="el-menu-vertical-demo" @open="handleOpen" @select="handleSelect" unique-opened router>
      <el-menu-item index="/web/appMyAiTool" @click="handleClick">
        <el-icon>
          <el-image :class="{ 'white-img': adaptiveStore.themeState }" class="img-icon"
            :src="require('@/assets/icon/vip_active.png')"></el-image>
        </el-icon>
        <router-link to="/web/appMyAiTool">
          <span>我的会员工具</span>
        </router-link>
      </el-menu-item>
      <el-sub-menu v-for="(item, index) in menuList" :key="index" :index="item.id">
        <template #title>
          <el-icon>
            <el-image :class="{ 'white-img': adaptiveStore.themeState }" class="img-icon"
              :src="item.iconUrl"></el-image>
          </el-icon>
          <span>{{ item.name }}</span>
        </template>

        <el-menu-item v-for="(childItem, childIndex) in item.children" :key="childIndex"
          :index="item.id + ',' + childItem.id">
          {{ childItem.name }}
        </el-menu-item>
      </el-sub-menu>
      <!-- 角色 -->
      <el-sub-menu index="role">
        <template #title>
          <el-icon>
            <el-image class="img-icon" :src="require('@/assets/icon/role_active.png')"
              :class="{ 'white-img': adaptiveStore.themeState }"></el-image>
          </el-icon>
          <span>角色</span>
        </template>
        <el-menu-item v-for="(items, indexs) in roleList" :key="indexs" :index="'role' + items.id">
          {{ items.name }}
        </el-menu-item>
      </el-sub-menu>

      <el-sub-menu index="person">
        <template #title>
          <el-icon>
            <el-image class="img-icon" :src="require('@/assets/icon/person_active.png')"
              :class="{ 'white-img': adaptiveStore.themeState }"></el-image>
          </el-icon>
          <span>个人中心</span>
        </template>
        <el-menu-item index="/personal">
          <el-icon><i class="iconfont">&#xe72a;</i></el-icon>
          <router-link to="/personal">
            <span>个人信息</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/collection">
          <el-icon><i class="iconfont">&#xe8c6;</i></el-icon>
          <router-link to="/personal/collection">
            <span>我的收藏</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/public-topic">
          <el-icon><i class="iconfont">&#xe645;</i></el-icon>
          <router-link to="/public-topic">
            <span>发布的主题</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/myPromotion">
          <el-icon><i class="iconfont">&#xe614;</i></el-icon>
          <router-link to="/personal/myPromotion">
            <span>我的推广</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/memberCenter">
          <el-icon><i class="iconfont">&#xe65d;</i></el-icon>
          <router-link to="/personal/memberCenter">
            <span>会员中心</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/application">
          <el-icon><i class="iconfont">&#xe712;</i></el-icon>
          <router-link to="/personal/application">
            <span>我的应用</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/firm">
          <el-icon><i class="iconfont">&#xe66e;</i></el-icon>
          <router-link to="/personal/firm">
            <span>企业信息</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/feedback">
          <el-icon><i class="iconfont">&#xe679;</i></el-icon>
          <router-link to="/personal/feedback">
            <span>问题反馈</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/personal/password">
          <el-icon><i class="iconfont">&#xe83e;</i></el-icon>
          <router-link to="/personal/password">
            <span>修改密码</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="" @click="logOutDialog">
          <el-icon><i class="iconfont">&#xe613;</i></el-icon>
          <span>退出登录</span>
        </el-menu-item>
      </el-sub-menu>
    </el-menu>
  </el-aside>
  <el-dialog v-model="logOutVisible" width="30%" :before-close="handleClose" :lock-scroll="false">
    <span style="color: red">是否确定退出登录？</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="logOutVisible = false">取消</el-button>
        <el-button type="primary" @click="logOut"> 确定 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import router from "@/router";
import { getCommonNewHome, getHomeFooter, getAppList } from "@/api/ai";
import { onMounted, ref, watch } from "vue";

import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();

let menuList = ref([]);
let roleList = ref([]);
let menuKey = ref("");

// watch(
//   () => router.currentRoute.value,
//   (newValue) => {
//     if (newValue) {
//       console.log(
//         "----菜单",
//         `${adaptiveStore.menuKey}, ${adaptiveStore.menuKey[1]}`
//       );
//       menuKey.value =`${adaptiveStore.menuKey}`;
//     }
//   }
// );

onMounted(() => {
  menuKey.value = adaptiveStore.menuKey;
  // menuList.value = adaptiveStore.sectionData;
  getMenu();
});

const activeIndex = ref("")

const handleClick = (item) => {
  activeIndex.value = item.index
}

async function getMenu() {
  let res = await getCommonNewHome();
  const data = res.data.data;
  menuList.value = data.sectionData.map((item) => {
    return {
      ...item,
      iconUrl: item.iconUrl
        ? process.env.VUE_APP_BASE_URI + "/media/" + item.iconUrl
        : "",
    };
  });
  roleList.value = data.role;
}

const handleOpen = (key, keyPath) => {
  adaptiveStore.menuKey = key;
  activeIndex.value = key
};
const handleSelect = (index, indexPath) => {
  if (index.includes(",")) {
    let category = index.split(",")[0];
    let childCategory = index.split(",")[1];
    let name = "";
    for (let i = 0; i < menuList.value.length; i++) {
      for (let j = 0; j < menuList.value[i].children.length; j++) {
        if (menuList.value[i].children[j].id == childCategory) {
          name = menuList.value[i].children[j].name;
        }
      }
    }
    router.push({
      path: "/web/appMyAiTool",
      query: {
        title: name,
        type: 1,
        state: 2,
        category,
        childCategory,
      },
    });
  } else {
    if (index.includes("role")) {
      let str = index.replace("role", "");
      router.push({
        path: "/web/appMyAiTool",
        query: {
          title: "角色",
          type: 1,
          state: 1,
          labelId: str,
        },
      });
    } else {
      router.push({ path: index });
    }
  }
  adaptiveStore.menuKey = index;
};
let logOutVisible = ref(false);
function logOutDialog() {
  logOutVisible.value = true;
  localStorage.removeItem("lastTriggerDate");
}
import { logout } from "@/api/ai";
import { removeCookie, removeToken, removeUser } from "@/utils/auth";
async function logOut() {
  let res = await logout();
  if (res.data.code == 0) {
    removeCookie("cookie");
    removeToken();
    removeUser();
    location.href = "/login";
  }
}
</script>

<style lang="scss" scoped>
.menu-box {
  position: sticky;
  top: 72px;
  background: var(--anchor-color);
}

.el-menu-item {
  font-size: 16px;
}

::v-deep .el-sub-menu__title {
  font-size: 16px;
}

.img-icon {
  width: 22px !important;
  height: 22px !important;
}
</style>
