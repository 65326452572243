<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
  <!-- data-server-rendered="true" -->
  <!-- <div id="app" class="app"> -->
  <!-- <metainfo></metainfo> -->
  <!-- <router-view v-slot="{ Component }" :key="$route.fullPath">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div> -->
</template>

<script setup>
import { onMounted } from "vue";
onMounted(() => {
  setTimeout(() => {
    document.dispatchEvent(new Event("render-event"));
  }, 6000);
});
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  // color: var(--text-color);
}
</style>
